import { IEntityOption } from '@app/core';
import {
  CommonConstant,
  ExportModeEnum,
  getExportFileName,
  IconName,
  IntegrationModeEnum, scrollToTop,
  TypeExportFileEnum,
  UserRoleEnum,
} from '@app/shared';
import { catchError, finalize, of, switchMap, tap } from 'rxjs';
import { DRAFT_STATUS_ICON_ASSETS } from 'src/shared/constants/draft-status.const';
import { DialogModeEnum } from 'src/shared/enums/dialog.enum';
import { DraftKeyStatusEnum, LanguageStatusEnum } from 'src/shared/enums/language-status.enum';
import { handleDownloadedFile } from 'src/shared/utils/file.util';
import { PermissionUtil } from '../../../shared/utils/permission.util';
import { ProjectUtil } from '../project/utils/project.util';
import { LanguageDetailFilterStatusEnum, TranslationErrorSeverityEnum } from './language-detail.enum';
import { ICurrentState, IDraftKeyStatus, IDraftKeyStatusIcon, ILanguageDetailPage } from './language-detail.interface';
import { getPageMode, handleNotReadyPublishConflictedTranslation } from './language-detail.util';
import { ITranslationSchema } from './translation-tool/translate-to/translation-row/text-editor-slate/text-editor.interface';

const generateGamePreviewButton = () => {
  return {
    icon: IconName.Link,
    btnLabel: 'Draft.FloatingBar.OpenGamePreview.Label',
    btnClass: 'btn__outline--leading',
    isHide: (currentState: ICurrentState) => {
      const project = currentState.projectState
      return !project?.appUrl
    },
    isDisabled: () => false,
    actionMethod: function () {
      const projectId = this.projectId
      ProjectUtil.openGamePreview(projectId)
    },
  }
}

export const PageMode = {
  [LanguageStatusEnum.OPEN]: {
    isEditable: false,
    headerClass: 'text-bubble__title--default',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState, language: string) => {
          if (!currentState || !language || !Object.keys(currentState).length) {
            return true
          }
          if ([UserRoleEnum.ADMIN, UserRoleEnum.DLM].includes(currentState.role)) {
            return false
          }
          const value = !currentState._user?.allowLanguages?.includes?.(language)
          return value
        },
        icon: IconName.FloatingBarPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
        actionMethod: function (language: ILanguageDetailPage) {
          const headerClass = getPageMode(LanguageStatusEnum[language?.status]).headerClass;
          this.pageMode = { ...getPageMode(LanguageStatusEnum.TO_EDIT), headerClass };
          this._changeDetector.detectChanges();
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
        btnClass: 'btn__floating-bar-review',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum.TO_REVIEW);
          this._changeDetector.detectChanges();
        },
      },
    ],
  },
  [LanguageStatusEnum.EDITED]: {
    isEditable: false,
    headerClass: 'text-bubble__title--modified',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState, language: string) => {
          if (!currentState || !language || !Object.keys(currentState).length) {
            return true
          }
          if ([UserRoleEnum.ADMIN, UserRoleEnum.DLM].includes(currentState.role)) {
            return false
          }
          const value = !currentState._user?.allowLanguages?.includes?.(language)
          return value
        },
        icon: IconName.FloatingBarPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
        actionMethod: function (language: ILanguageDetailPage) {
          const headerClass = getPageMode(LanguageStatusEnum[language?.status]).headerClass;
          this.pageMode = { ...getPageMode(LanguageStatusEnum.TO_EDIT), headerClass };
          this._changeDetector.detectChanges();
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
        btnClass: 'btn__floating-bar-review',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum.PAGE_REVIEW_MODE);
          this.pageMode.headerClass = PageMode[language?.status].headerClass;
          this._changeDetector.detectChanges();
        },
      },
    ],
  },
  [LanguageStatusEnum.TO_EDIT]: {
    isEditable: true,
    headerClass: 'text-bubble__title--modified',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
      {
        isDisabled: false,
        btnLabel: 'Draft.FloatingBar.BackToViewMode.Label',
        btnClass: 'btn--static-grey',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum[this.originalDataSource?.status]);
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return false;
        },
        isToggleDisabledState: true,
        icon: IconName.FloatingBarCheck,
        disabledIcon: IconName.FloatingBarDisabledCheck,
        localSpinnerId: 'SUBMIT_SPINNER_ID',
        btnLabel: 'Draft.FloatingBar.EditSubmit.Label',
        btnClass: 'btn__floating-bar-submit',
        actionMethod: function (language: ILanguageDetailPage) {
          this.subscribe(
            this._localSpinnerService.withLocalSpinner(
              this._projectsService.updateLanguageStatus(this.draftId, LanguageStatusEnum.TO_REVIEW).pipe(
                tap((response) => {
                  this.errorKeys = []
                  if (response === DialogModeEnum.CANCEL_LITE_FIX || response === DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING) {
                    return;
                  }
                  this.backToLanguages();
                }),
                catchError((err) => {
                  this.errorKeys = []
                  if (Array.isArray(err?.error)) {
                    this.errorKeys = err.error.map(v => v.key)
                    return handleNotReadyPublishConflictedTranslation.apply(this, [err?.error, LanguageStatusEnum.TO_REVIEW]);
                  } else {
                    this._openSnackBar(err?.error?.message || 'Global.Toast.Label.Error', CommonConstant.FAILURE_SNACKBAR_CONFIG);
                  }
                  throw err;
                }),
              ),
              'SUBMIT_SPINNER_ID',
            ),
          )
        },
      },
    ],
  },
  [LanguageStatusEnum.TO_REVIEW]: {
    isEditable: false,
    headerClass: 'text-bubble__title--to-review',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
        actionMethod: function (language: ILanguageDetailPage) {
          const headerClass = getPageMode(LanguageStatusEnum[language?.status]).headerClass;
          this.pageMode = { ...getPageMode(LanguageStatusEnum.TO_EDIT), headerClass };
          this._changeDetector.detectChanges();
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return !PermissionUtil.canUserReviewDraft(currentState.role)
        },
        icon: IconName.FloatingBarReview,
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
        btnClass: 'btn__floating-bar-review--accepted',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum.PAGE_REVIEW_MODE);
          this.pageMode.headerClass = PageMode[language?.status].headerClass;
          this._changeDetector.detectChanges();
        },
      },
    ],
  },
  [LanguageStatusEnum.PAGE_REVIEW_MODE]: {
    isEditable: true,
    headerClass: 'text-bubble__title--to-review',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
      {
        isDisabled: false,
        btnLabel: 'Draft.FloatingBar.BackToViewMode.Label',
        btnClass: 'btn--static-grey',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum[this.originalDataSource?.status]);
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return false;
        },
        icon: IconName.FloatingBarCross,
        btnLabel: 'Draft.FloatingBar.RejectedChanges.Label',
        btnClass: 'btn__warning',
        actionMethod: function (language: ILanguageDetailPage) {
          this._localSpinnerService.startLocalSpinner(this.LOCAL_SPINNER_ID);
          this._projectsService
            .updateLanguageStatus(this.draftId, LanguageStatusEnum.REJECTED)
            .pipe(
              tap(() => {
                this._localSpinnerService.stopLocalSpinner(this.LOCAL_SPINNER_ID);
              }),
              switchMap(() => {
                return this._initialActions();
              }),
            )
            .subscribe((response) => {
              this.pageMode = getPageMode(LanguageStatusEnum[this.originalDataSource$?.value?.status]);
              this._changeDetector.detectChanges();
            });
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return false;
        },
        icon: IconName.FloatingBarCheck,
        btnLabel: 'Draft.FloatingBar.Publish.Label',
        btnClass: 'btn__ready',
        actionMethod: function (language: ILanguageDetailPage) {
          this._localSpinnerService.startLocalSpinner(this.LOCAL_SPINNER_ID);
          this._projectsService
            .updateLanguageStatus(this.draftId, LanguageStatusEnum.PUBLISHED)
            .pipe(
              catchError((err) => {
                this._localSpinnerService.stopLocalSpinner(this.LOCAL_SPINNER_ID);
                if (Array.isArray(err?.error)) {
                  return handleNotReadyPublishConflictedTranslation.apply(this, [err?.error, LanguageStatusEnum.PUBLISHED]);
                } else {
                  this._openSnackBar(err?.error?.message || 'Global.Toast.Label.Error', CommonConstant.FAILURE_SNACKBAR_CONFIG);
                }
                throw err;
              }),
              switchMap((response) => {
                if (
                  response !== DialogModeEnum.CANCEL_LITE_FIX &&
                  response !== DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING &&
                  !!response
                ) {
                  this._localSpinnerService.startLocalSpinner(this.LOCAL_SPINNER_ID);
                  if (this.currentState?.projectState?.integrationMode === IntegrationModeEnum.CSV_202) {
                    return this._projectsService
                      .exportTranslations(
                        this.projectId,
                        this.draftId,
                        this.sourceLanguage.code,
                        ExportModeEnum.DRAFT_CSV_202_HISTORY,
                      )
                      .pipe(
                        finalize(() => this._localSpinnerService.stopLocalSpinner(this.LOCAL_SPINNER_ID)),
                        switchMap((exportedFile: Blob) => {
                          handleDownloadedFile(
                            exportedFile,
                            `${getExportFileName(this.language, ExportModeEnum.DRAFT_CSV_202_HISTORY)}.${TypeExportFileEnum.CSV}`,
                          );
                          return of(response);
                        }),
                      );
                  }
                }
                return of(response);
              }),
            )
            .subscribe((response) => {
              if (response === DialogModeEnum.CANCEL_LITE_FIX || response === DialogModeEnum.UPDATE_DRAFT_KEY_STATUS_DO_NOTHING) {
                return;
              }
              this._lottieAnimationService.setIsPublishing(true);
              this.backToLanguages();
            });
        },
      },
    ],
  },
  [LanguageStatusEnum.PUBLISHED]: {
    isEditable: false,
    headerClass: 'text-bubble__title--default',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState, language: string) => {
          if (!currentState || !language || !Object.keys(currentState).length) {
            return true
          }
          if ([UserRoleEnum.ADMIN, UserRoleEnum.DLM].includes(currentState.role)) {
            return false
          }
          const value = !currentState._user?.allowLanguages?.includes?.(language)
          return value
        },
        icon: IconName.FloatingBarDisabledPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
        actionMethod: function (language: ILanguageDetailPage) {
          const headerClass = getPageMode(LanguageStatusEnum[language?.status]).headerClass;
          this.pageMode = { ...getPageMode(LanguageStatusEnum.TO_EDIT), headerClass };
          this._changeDetector.detectChanges();
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
        btnClass: 'btn__floating-bar-review',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum.TO_REVIEW);
          this._changeDetector.detectChanges();
        },
      },
    ],
  },
  [LanguageStatusEnum.REJECTED]: {
    isEditable: false,
    headerClass: 'text-bubble__title--rejected',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return false;
        },
        isHide: (currentState: ICurrentState) => {
          return false;
        },
        icon: IconName.FloatingBarRejectedPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__warning',
        actionMethod: function (language: ILanguageDetailPage) {
          const headerClass = getPageMode(LanguageStatusEnum[language?.status]).headerClass;
          this.pageMode = { ...getPageMode(LanguageStatusEnum.TO_EDIT), headerClass };
          this._changeDetector.detectChanges();
        },
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
        btnClass: 'btn__floating-bar-review',
        actionMethod: function (language: ILanguageDetailPage) {
          this.pageMode = getPageMode(LanguageStatusEnum.PAGE_REVIEW_MODE);
          this.pageMode.headerClass = PageMode[language?.status].headerClass;
          this._changeDetector.detectChanges();
        },
      },
    ],
  },
  [LanguageStatusEnum.TO_MERGE]: {
    isEditable: false,
    headerClass: 'text-bubble__title--modified',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnClass: 'btn__floating-bar-review',
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
      },
    ],
  },
  [LanguageStatusEnum.TO_DEPLOY]: {
    isEditable: false,
    headerClass: 'text-bubble__title--modified',
    startSectionActions: [
      {
        isDisabled: false,
        icon: IconName.ToTop,
        btnClass: 'btn__floating-bar-to-top',
        actionMethod: function (language: ILanguageDetailPage) {
          scrollToTop();
        },
      },
    ],
    endSectionActions: [
      generateGamePreviewButton(),
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !(currentState.isAdmin);
        },
        icon: IconName.FloatingBarDisabledPen,
        btnLabel: 'Draft.FloatingBar.EditingMode.Label',
        btnClass: 'btn__outline--leading',
      },
      {
        isDisabled: (currentState: ICurrentState) => {
          return true;
        },
        isHide: (currentState: ICurrentState) => {
          return !currentState?.isAdmin;
        },
        icon: IconName.FloatingBarDisabledReview,
        btnClass: 'btn__floating-bar-review',
        btnLabel: 'Draft.FloatingBar.ReviewMode.Label',
      },
    ],
  },
};

export const DraftKeyStatus: { [key: string]: IDraftKeyStatus } = {
  [DraftKeyStatusEnum.DEFAULT]: {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.DEFAULT],
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: (currentState: ICurrentState) => {
      return false;
    },
  },
  [DraftKeyStatusEnum.EDITED]: {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.EDITED],
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: (currentState: ICurrentState) => {
      return false;
    },
  },
  [DraftKeyStatusEnum.WIP]: {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.WIP],
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: (currentState: ICurrentState) => {
      return false;
    },
  },
  [DraftKeyStatusEnum.VALIDATED]: {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.VALIDATED],
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: (currentState: ICurrentState) => {
      return !currentState?.isAdmin;
    },
  },
  [DraftKeyStatusEnum.REJECTED]: {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.REJECTED],
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: (currentState: ICurrentState) => {
      return false;
    },
  },
};

export const DraftKeyStatusIcons: IDraftKeyStatusIcon[] = [
  {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.CLOSE],
    isHide: (isAdmin?: boolean) => {
      return false;
    },
    isDisabled: () => {
      return false;
    },
  },
  {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.WIP],
    action: function (draftItem: ITranslationSchema) {
      this._draftService
        .updateDraftKeyStatus(draftItem?.draftId, draftItem?.editedItemId, DraftKeyStatusEnum.WIP)
        .pipe(
          catchError((err) => {
            this._openSnackBar(err?.error?.message || 'Global.Toast.Label.Error', CommonConstant.FAILURE_SNACKBAR_CONFIG);
            throw err;
          }),
        )
        .subscribe(() => {
          this.originalDataSource['translationSchema'][draftItem.index] = draftItem;
        });
    },
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: () => {
      return false;
    },
  },
  {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.VALIDATED],
    action: function (draftItem: ITranslationSchema) {
      this._draftService
        .updateDraftKeyStatus(draftItem?.draftId, draftItem?.editedItemId, DraftKeyStatusEnum.VALIDATED)
        .pipe(
          catchError((err) => {
            this._openSnackBar(err?.error?.message || 'Global.Toast.Label.Error', CommonConstant.FAILURE_SNACKBAR_CONFIG);
            throw err;
          }),
        )
        .subscribe(() => {
          this.originalDataSource['translationSchema'][draftItem.index] = draftItem;
        });
    },
    isHide: (currentState: ICurrentState) => {
      return !currentState?.isAdmin;
    },
    isDisabled: () => {
      return false;
    },
  },
  {
    ...DRAFT_STATUS_ICON_ASSETS[DraftKeyStatusEnum.REJECTED],
    action: function (draftItem: ITranslationSchema) {
      this._draftService
        .updateDraftKeyStatus(draftItem?.draftId, draftItem?.editedItemId, DraftKeyStatusEnum.REJECTED)
        .pipe(
          catchError((err) => {
            this._openSnackBar(err?.error?.message || 'Global.Toast.Label.Error', CommonConstant.FAILURE_SNACKBAR_CONFIG);
            throw err;
          }),
        )
        .subscribe(() => {
          this.originalDataSource['translationSchema'][draftItem.index] = draftItem;
        });
    },
    isHide: (currentState: ICurrentState) => {
      return false;
    },
    isDisabled: () => {
      return false;
    },
  },
];

export const translationErrorCodeOrder = {
  [TranslationErrorSeverityEnum.REJECTED]: 1,
  [TranslationErrorSeverityEnum.WIP]: 2,
  [TranslationErrorSeverityEnum.ERROR]: 3,
  [TranslationErrorSeverityEnum.ALERT]: 4,
  [TranslationErrorSeverityEnum.INFO]: 5,
};

export const LanguageFilterStateActions = {
  [LanguageDetailFilterStatusEnum.ALL_KEYS]: (item) => {
    return !!item?.index;
  },
  [LanguageDetailFilterStatusEnum.NEW_KEYS]: (item) => {
    return !!item?.isEdited;
  },
  [LanguageDetailFilterStatusEnum.MISSING_TRANSLATION]: (item) => {
    return !item.value || item.displayKey === item.value;
  },
  [LanguageDetailFilterStatusEnum.NO_SECTION]: (item) => {
    return !item.section;
  },
  [LanguageDetailFilterStatusEnum.ALL_SECTIONS]: (item) => {
    return item;
  },
  [LanguageDetailFilterStatusEnum.AUDIO_ONLY]: (item) => {
    return !!item?.allowAudio;
  },
};

export const LanguageFilterValue = {
  [LanguageDetailFilterStatusEnum.ALL_KEYS]: 'Draft.Filter.Value.AllKeys.Label',
  [LanguageDetailFilterStatusEnum.NEW_KEYS]: 'Draft.Filter.Value.NewKeys.Label',
  [LanguageDetailFilterStatusEnum.MISSING_TRANSLATION]: 'Draft.Filter.Value.MissingTranslation.Label',
  [LanguageDetailFilterStatusEnum.ALL_SECTIONS]: 'Draft.Filter.Value.Sections.Label',
  [LanguageDetailFilterStatusEnum.NO_SECTION]: 'Draft.Filter.Value.NoSection.Label',
  [LanguageDetailFilterStatusEnum.AUDIO_ONLY]: 'Draft.Filter.Value.AudioOnly.Label',
};

export const DefaultLanguageFilterOptions = [
  {
    id: LanguageDetailFilterStatusEnum.ALL_KEYS,
    name: LanguageFilterValue[LanguageDetailFilterStatusEnum.ALL_KEYS],
  },
  {
    id: LanguageDetailFilterStatusEnum.NEW_KEYS,
    name: LanguageFilterValue[LanguageDetailFilterStatusEnum.NEW_KEYS],
  },
  {
    id: LanguageDetailFilterStatusEnum.MISSING_TRANSLATION,
    name: LanguageFilterValue[LanguageDetailFilterStatusEnum.MISSING_TRANSLATION],
  },
  {
    id: LanguageDetailFilterStatusEnum.AUDIO_ONLY,
    name: LanguageFilterValue[LanguageDetailFilterStatusEnum.AUDIO_ONLY],
  },
];

export const DefaultLanguageFilterOption: IEntityOption = {
  id: LanguageDetailFilterStatusEnum.ALL_KEYS,
  name: LanguageFilterValue[LanguageDetailFilterStatusEnum.ALL_KEYS],
};
export const DefaultSectionOption: IEntityOption = {
  id: LanguageDetailFilterStatusEnum.ALL_SECTIONS,
  name: LanguageFilterValue[LanguageDetailFilterStatusEnum.ALL_SECTIONS],
};

export const NoSectionOption: IEntityOption = {
  id: LanguageDetailFilterStatusEnum.NO_SECTION,
  name: LanguageFilterValue[LanguageDetailFilterStatusEnum.NO_SECTION],
};

export const IMPORT_TRANSLATION_BUTTON_DISABLED_STATE = {
  [LanguageStatusEnum.OPEN]: false,
  [LanguageStatusEnum.EDITED]: false,
  [LanguageStatusEnum.TO_REVIEW]: false,
  [LanguageStatusEnum.REJECTED]: false,
  [LanguageStatusEnum.TO_MERGE]: true,
  [LanguageStatusEnum.TO_DEPLOY]: true,
  [LanguageStatusEnum.PUBLISHED]: true,
};

export const IMPORT_TRANSLATION_BUTTON_HIDDEN_STATE = {
  [LanguageStatusEnum.OPEN]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
  [LanguageStatusEnum.EDITED]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
  [LanguageStatusEnum.TO_REVIEW]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
  [LanguageStatusEnum.REJECTED]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
  [LanguageStatusEnum.TO_MERGE]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
  [LanguageStatusEnum.TO_DEPLOY]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },

  [LanguageStatusEnum.PUBLISHED]: (currentState: ICurrentState) => {
    return !currentState?.isAdmin;
  },
};

export const TRANSLATION_UPDATE_TO_EDITED_STATUS = {
  [LanguageStatusEnum.OPEN]: true,
  [LanguageStatusEnum.PUBLISHED]: true,
};

export const DIALOG_WIDTH_DESKTOP = '445px';
export const DIALOG_HEIGHT_DESKTOP = '440px';
export const DIALOG_DEFAULT_PANELCLASS = 'custom-dialog-container';
export const DIALOG_DEFAULT_POSITION = { top: '100px' };
export const DIALOG_DEFAULT_HASBACKDROP = true;
export const DIALOG_DEFAULT_DISABLECLOSE = true;
