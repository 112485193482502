import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRouteEnum, AuthGuard, SystemAdminGuard, UserGuard } from '../shared';
import { AppValidationGuard } from '../shared/guards/app-validation.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: AppRouteEnum.Groups,
  },
  {
    path: AppRouteEnum.Error,
    loadChildren: () => import('./modules/error/error.module').then((m) => m.ErrorModule),
  },
  {
    path: AppRouteEnum.Home,
    // loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
    // canActivate: [AppValidationGuard, UserGuard],
    redirectTo: AppRouteEnum.Groups,
  },
  {
    path: AppRouteEnum.AppValidation,
    loadChildren: () => import('./modules/app-validation/app-validation.module').then((m) => m.AppValidationModule),
  },
  {
    path: AppRouteEnum.Welcome,
    loadChildren: () => import('./modules/welcome/welcome.module').then((m) => m.WelcomeModule),
  },
  {
    path: AppRouteEnum.App,
    loadChildren: () =>
      import('./modules/translation-tool-wrapper/translation-tool-wrapper.module').then((m) => m.TranslationToolWrapperModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.Auth,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: `${AppRouteEnum.Module}/:id`,
    loadChildren: () => import('./modules/module-detail/module-detail.module').then((m) => m.ModuleDetailModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: `${AppRouteEnum.ThemeGroup}/:id`,
    loadChildren: () => import('./modules/theme/theme.module').then((m) => m.ThemeModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: `${AppRouteEnum.Project}/:id`,
    loadChildren: () => import('./modules/project-detail/project-detail.module').then((m) => m.ProjectDetailModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.AccountManagement,
    loadChildren: () => import('./modules/account-management').then((routes) => routes.AccountManagementRoutes),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: `${AppRouteEnum.ProjectSettings}/:projectId`,
    loadComponent: () => import('./modules/project-settings/project-settings.component').then(c => c.ProjectSettingsComponent),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: `${AppRouteEnum.KeyManagement}/:projectId`,
    loadChildren: () => import('./modules/key-management/key-management.module').then((m) => m.KeyManagementModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.Groups,
    loadChildren: () => import('./modules/groups/groups.module').then((routes) => routes.GroupsModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.PodcastGroup,
    loadChildren: () => import('./modules/podcast-group/podcast.routes').then(r => r.PODCAST_ROUTES),
    canActivate: [UserGuard],
  },
  {
    path: AppRouteEnum.VideoSubtitleGroup,
    loadChildren: () => import('./modules/video-subtitle-group/video-subtitle.routes').then(r => r.VIDEO_SUBTITLE_ROUTES),
    canActivate: [UserGuard],
  },
  {
    path: `${AppRouteEnum.Language}/:projectId/:language/:draftId`,
    loadChildren: () => import('./modules/language-detail/language-detail.module').then((m) => m.LanguageDetailModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.Glossary,
    loadChildren: () => import('./modules/glossary/glossary.module').then((m) => m.GlossaryModule),
    canActivate: [AppValidationGuard, UserGuard],
  },
  {
    path: AppRouteEnum.SSO,
    loadChildren: () => import('./modules/chanel-sso/chanel-sso.module').then((m) => m.ChanelSsoModule),
  },
  {
    path: AppRouteEnum.FAQ,
    loadChildren: () => import('./modules/faq/faq.module').then((m) => m.FAQModule),
  },
  {
    path: AppRouteEnum.GamePreview,
    loadChildren: () => import('./modules/game-preview/game-preview.module').then((m) => m.GamePreviewModule),
  },
  {
    path: '**',
    redirectTo: '/groups',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  providers: [AppValidationGuard, AuthGuard, SystemAdminGuard],
  exports: [RouterModule],
})
export class AppRoutingModule { }
