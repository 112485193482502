export enum AppRouteEnum {
  AccountManagement = 'account-management',
  KeyManagement = 'key-management',
  ProjectSettings = 'project-settings',
  App = 'app',
  Home = 'home',
  Groups = 'groups',
  AppValidation = 'app-validation',
  Auth = 'auth',
  Settings = 'settings',
  SignIn = 'sign-in',
  ResetPassword = 'reset-password',
  ForgotPassword = 'forgot-password',
  TokenExpired = 'token-expired',
  Welcome = 'welcome',
  AccountDeactivated = 'account-deactivated',
  Dashboard = 'dashboard',
  Module = 'module',
  Project = 'project',
  Language = 'language',
  Glossary = 'glossary',
  SSO = 'sso',
  FAQ = 'faq',
  Landing = 'landing',
  Error = 'error',
  GamePreview = 'game-preview',
  Themes = 'themes',
  Passports = 'passports',
  ThemeGroup = 'theme-group',
  PodcastGroup = 'podcast-group',
  Podcasts = 'podcasts',
  VideoSubtitleGroup = 'video-subtitle-group',
  VideoSubtitles = 'video-subtitles',
}
